import { t } from 'i18next';
import { HTMLAttributes } from 'react';
import { PersonDescription } from '../../../services/content_v1';
import { UserProfileMinimal } from '../../../services/users_v1';
import { useIsAdmin } from '../../common';

interface NoHideAdminProps extends HTMLAttributes<HTMLSpanElement> {
    user: UserProfileMinimal | PersonDescription | undefined | null;
}

function NoHideEmailAdmin({ user, ...props }: NoHideAdminProps) {
    const isAdmin = useIsAdmin();

    return (
        user?.hide_email ?
            (isAdmin ?
                <a {...props} href={`mailto:${user.email}`} className="user-settings__label">{user.email || ''}</a>
                :
                <span {...props} className="user-settings__label">{t('account:HIDDEN_EMAIL')}</span>
            ) :
            <a {...props} href={`mailto:${user?.email}`} className="link-accent link-underline">
                {user?.email || ''}
            </a>
    );
}

export default NoHideEmailAdmin;