import { t } from 'i18next';
import { HTMLAttributes } from 'react';
import { PersonDescription } from '../../../services/content_v1';
import { UserProfileMinimal } from '../../../services/users_v1';
import { useIsAdmin } from '../../common';

interface NoHideAdminProps extends HTMLAttributes<HTMLSpanElement> {
    user: UserProfileMinimal | PersonDescription | undefined | null;
}

function NoHidePhoneAdmin({ user, ...props }: NoHideAdminProps) {
    const isAdmin = useIsAdmin();

    return (
        user?.hide_phone ?
            (isAdmin ?
                <a href={`tel:${user.phone}`}>
                    <span {...props} className="user-settings__label" style={{ whiteSpace: 'nowrap' }}>
                        {user.phone || ''}
                    </span>
                </a>
                :
                <span {...props} className="user-settings__label">{t('account:HIDDEN_PHONE')}</span>
            ) :
            <a href={`tel:${user?.phone}`}>
                <span {...props} className="phone" style={{ whiteSpace: 'nowrap' }}>{user?.phone || ''}</span>
            </a>
    );
}

export default NoHidePhoneAdmin;