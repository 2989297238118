/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createApi } from '@reduxjs/toolkit/query/react';
import { EMPTY_ROLE } from '../const';
import { baseQueryWithReauth } from './reauth';
import { AuthResponse, Role, userApiV1 } from './users_v1';

export type Language = 'ru' | 'en';

export const CONSUMERS = 'consumers';
export const VIP = 'favorites';
export const BLOCK = 'blocked';

export interface AuthState {
    user?: string
    account?: string
    accessToken?: string
    refreshToken?: string
    suUser?: string
    suRefresh?: string
}

export interface DashboardData {
    storage: {
        total: number,
        used: number,
        free: number
    },
    speed: {
        max: number
    },
    access_requests: {
        all: number,
        opened: number,
        pending: number,
        granted: number,
        granted_downloaded: number,
        rejected: number,
        canceled: number
    },
    consumers: {
        total: number
    },
    content: {
        total: number,
        media: number,
        tv: number,
        new: number,
        new_tv: number,
        new_media: number,
    },
    transcoded: {
        finished: number,
        failed: number,
        processed: number
    }
}

export interface FeedbackRequest {
    email: string,
    text: string,
    token?: string
}

export interface LoginRequest {
    username: string
    password: string
}

export interface LoginSuRequest {
    refresh: string
    login_as: string
    suUser: string
    suRefresh: string
}

export interface LogoutSuRequest {
    refresh: string
    username: string
    account?: string
}

export interface OffsetLimit {
    offset: number,
    limit: number,
    [key: string]: string | number
}

export interface Profile {
    phone: string,
    firstname: string,
    lastname: string,
    company: string,
    avatar?: string,
    send_emails: boolean,
    emails?: string[],
    send_sms: boolean,
    phones?: string[]
    send_telegrams: boolean,
    telegrams?: string[],
    comment?: string
}

export interface Status {
    code: number,
    msg: string
}

export interface StatusResponse<T> {
    status: Status,
    results: T
}

export interface StatusPagesResponse<T> {
    status: Status,
    results: {
        count: number,
        next: string | null,
        previous: string | null,
        data: T[]
    }
}

export interface SummaryData {
    tools: {
        transcoding_requests: number,
        uploading: number
    },
    access_requests: {
        all: number,
        opened: number,
        pending: number,
        granted: number,
        granted_downloaded: number,
        rejected: number,
        canceled: number
    },
    notifictions: {
        new: number
    }
}

export interface StringMap {
    [key: string]: string
}

export interface AccountPreference {
    prefferedSourceTV: boolean | null
    prefferedSourceUnit: boolean | null
    sourceSatellite: boolean | null
    sourceMedialodistic: boolean | null
    sourceOther: boolean | null
    agr_offer?: boolean | null
}

export interface SpecData {
    have_media_units: boolean,
    have_tv_units: boolean,
    have_media_requests: boolean,
    have_tv_requests: boolean,
    have_tv_permissions: boolean,
    is_tv_rightowner: boolean
}

export const commonApi = createApi({
    reducerPath: 'commonApi',
    baseQuery: baseQueryWithReauth,
    tagTypes: ['Users', 'UserRequests', 'Content', 'Access', 'Notify', 'Upload', 'Media', 'Transcode'],
    keepUnusedDataFor: 30,
    endpoints: (builder) => ({
        getSummary: builder.query<StatusResponse<SummaryData>, void>({
            query: () => 'be/api/ui/summary/',
            providesTags: (result, error, id) => [{ type: 'Content' }, { type: 'Access' }, { type: 'Notify' }, { type: 'Transcode' }],
        }),
        getDashboard: builder.query<StatusResponse<DashboardData>, void>({
            query: () => 'be/api/ui/dashboard/',
            providesTags: (result, error, id) => [{ type: 'Content' }, { type: 'Access' }, { type: 'Notify' }, { type: 'Transcode' }],
        }),
        getSpecs: builder.query<StatusResponse<SpecData>, void | number>({
            query: (acc_id) => `be/api/v1/ui/specs/${acc_id ? ('?id=' + acc_id) : ''}`,
        }),
        getUnits: builder.query<StatusResponse<Pick<DashboardData, 'content'>>, number | undefined>({
            query: (accound_id) => `be/api/v1/ui/units/?id=${accound_id || ''}`,
            providesTags: (result, error, id) => [{ type: 'Content' }],
        }),
        getXls: builder.mutation<string, string>({
            query: (url) => ({
                url: url,
                responseType: 'blob',
                responseDataHandler: async (data: BlobPart) => {
                    const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
                    return URL.createObjectURL(blob);
                },
                cache: 'no-cache',
            }),
        }),

        login: builder.mutation<AuthResponse, LoginRequest>({
            query: (credentials: LoginRequest) => ({
                url: 'be/token/',
                method: 'POST',
                body: credentials,
            }),
            transformResponse: (response: AuthResponse, meta, arg) => {
                const result = { ...response, username: arg.username }
                return result;
            },
        }),
        accountLogin: builder.mutation<AuthResponse, LogoutSuRequest>({
            query: (credentials: LogoutSuRequest) => ({
                url: 'be/token/refresh/',
                method: 'POST',
                body: credentials,
            }),
            transformResponse: (response: AuthResponse, meta, arg) => {
                const result = { ...response, username: arg.username };
                return result;
            },
            invalidatesTags: (result, error, id) => [{ type: 'Access' }, { type: 'Content' }, { type: 'Users' }, { type: 'Notify' }]
        }),
        suLogin: builder.mutation<AuthResponse, LoginSuRequest>({
            query: (credentials: LoginSuRequest) => ({
                url: 'be/token/su/',
                method: 'POST',
                body: credentials,
            }),
            transformResponse: (response: AuthResponse, meta, arg) => {
                const result = { ...response, username: arg.login_as, suUser: arg.suUser, suRefresh: arg.suRefresh };
                return result;
            },
            invalidatesTags: (result, error, id) => [{ type: 'Access' }, { type: 'Content' }, { type: 'Users' }, { type: 'Notify' }]
        }),
        suLogout: builder.mutation<AuthResponse, LogoutSuRequest>({
            query: (credentials: LogoutSuRequest) => ({
                url: 'be/token/refresh/',
                method: 'POST',
                body: credentials,
            }),
            transformResponse: (response: AuthResponse, meta, arg) => {
                const result = { ...response, refresh: arg.refresh, username: arg.username, suUser: '', suRefresh: '' };
                return result;
            },
            invalidatesTags: (result, error, id) => [{ type: 'Access' }, { type: 'Content' }, { type: 'Users' }, { type: 'Notify' }]
        }),
        sendToSupport: builder.mutation<StatusPagesResponse<{ text: string }>, string>({
            query: (text: string) => ({
                url: 'be/api/support/message/send/',
                method: 'POST',
                body: { text },
            }),
        }),
        sendFeedback: builder.mutation<StatusPagesResponse<{ text: string, email: string }>, FeedbackRequest>({
            query: (data) => ({
                url: 'be/api/feedback/',
                method: 'POST',
                body: data,
            }),
        }),
    }),
})

const slice = createSlice({
    name: 'auth',
    initialState: { user: localStorage.user || undefined, account: localStorage.account || undefined, accessToken: localStorage.accessToken, refreshToken: localStorage.refreshToken, suUser: localStorage.suUser, suRefresh: localStorage.suRefresh },
    reducers: {
        setCredentials: (
            state,
            { payload: { user, account, accessToken, refreshToken, suUser, suRefresh } }: PayloadAction<AuthState>
        ) => {
            if (user !== undefined) { state.user = user; localStorage.user = user; }
            if (account !== undefined) { state.account = account; localStorage.account = account; }
            if (accessToken !== undefined) { state.accessToken = accessToken; localStorage.accessToken = accessToken; }
            if (refreshToken !== undefined) { state.refreshToken = refreshToken; localStorage.refreshToken = refreshToken; }
            if (suUser !== undefined) { state.suUser = suUser; localStorage.suUser = suUser; }
            if (suRefresh !== undefined) { state.suRefresh = suRefresh; localStorage.suRefresh = suRefresh; }

            // if (state.user !== undefined) localStorage.user = state.user;
            // if (state.account !== undefined) localStorage.account = state.account;
            // if (state.accessToken !== undefined) localStorage.accessToken = state.accessToken;
            // if (state.refreshToken !== undefined) localStorage.refreshToken = state.refreshToken;
            // if (state.suUser !== undefined) localStorage.suUser = state.suUser;
            // if (state.suRefresh !== undefined) localStorage.suRefresh = state.suRefresh;
        },
    },
    extraReducers: (builder) => {
        builder.addMatcher(
            commonApi.endpoints.login.matchFulfilled,
            (state, { payload }) => {
                const jwt = payload.access.split('.')[1];
                const jwtDecoded = JSON.parse(atob(jwt));

                state.accessToken = payload.access
                state.refreshToken = payload.refresh
                state.user = payload.username
                state.account = jwtDecoded.accound_id
                state.suUser = ''
                state.suRefresh = ''

                localStorage.accessToken = payload.access
                localStorage.refreshToken = payload.refresh
                localStorage.user = payload.username
                localStorage.account = jwtDecoded.accound_id || ''
                localStorage.suUser = ''
                localStorage.suRefresh = ''
            },
        ).addMatcher(
            commonApi.endpoints.accountLogin.matchFulfilled,
            (state, { payload }) => {
                const jwt = payload.access.split('.')[1];
                const jwtDecoded = JSON.parse(atob(jwt));

                state.accessToken = payload.access
                if (payload.refresh) state.refreshToken = payload.refresh
                state.user = payload.username
                state.account = jwtDecoded.accound_id

                localStorage.accessToken = payload.access
                if (payload.refresh) localStorage.refreshToken = payload.refresh
                localStorage.user = payload.username
                localStorage.account = jwtDecoded.accound_id || ''
            },
        ).addMatcher(
            commonApi.endpoints.suLogin.matchFulfilled,
            (state, { payload }) => {
                const jwt = payload.access.split('.')[1];
                const jwtDecoded = JSON.parse(atob(jwt));

                state.accessToken = payload.access
                state.refreshToken = payload.refresh
                state.user = payload.username
                state.account = jwtDecoded.accound_id || ''
                state.suUser = payload.suUser
                state.suRefresh = payload.suRefresh

                localStorage.accessToken = payload.access
                localStorage.refreshToken = payload.refresh
                localStorage.user = payload.username
                localStorage.account = jwtDecoded.accound_id || ''
                localStorage.suUser = payload.suUser
                localStorage.suRefresh = payload.suRefresh
            },
        ).addMatcher(
            commonApi.endpoints.login.matchRejected,
            (state, { payload }) => {
                state.accessToken = ''
                state.refreshToken = ''
                state.user = ''
                state.account = ''
                state.suUser = ''
                state.suRefresh = ''

                delete localStorage.accessToken;
                delete localStorage.refreshToken;
                delete localStorage.user;
                delete localStorage.account;
                delete localStorage.suUser;
                delete localStorage.suRefresh;
            }
        ).addMatcher(
            commonApi.endpoints.suLogout.matchFulfilled,
            (state, { payload }) => {
                const jwt = payload.access.split('.')[1];
                const jwtDecoded = JSON.parse(atob(jwt));

                state.accessToken = payload.access
                state.refreshToken = payload.refresh
                state.user = payload.username
                state.account = jwtDecoded.accound_id
                state.suUser = ''
                state.suRefresh = ''

                localStorage.accessToken = payload.access
                localStorage.refreshToken = payload.refresh
                localStorage.user = payload.username
                localStorage.account = jwtDecoded.accound_id
                localStorage.suUser = ''
                localStorage.suRefresh = ''
            },
        )
    },
});

const roleSlice = createSlice({
    name: 'role',
    initialState: EMPTY_ROLE,
    reducers: {
        setRole: (
            state,
            { payload: { id, label: name, name: displayname, ts } }: PayloadAction<Role>
        ) => {
            state.id = id
            state.label = name
            state.name = displayname
            state.ts = ts
        }
    },
    extraReducers: (builder) => {
        builder.addMatcher(
            userApiV1.endpoints.getProfile.matchFulfilled,
            (state, { payload }) => {
                const ts = payload.ts;
                if (!state.ts || !ts || state.ts < ts) {
                    const tmp = payload.results.roles.at(0) || EMPTY_ROLE
                    state.id = tmp.id;
                    state.label = tmp.label;
                    state.name = tmp.name;
                    state.ts = ts
                }
            }
        ).addMatcher(
            commonApi.endpoints.login.matchRejected,
            (state, { payload }) => {
                state = EMPTY_ROLE
            }
        )
    },
});

const accSliceInitialState: AccountPreference = { prefferedSourceTV: null, prefferedSourceUnit: null, sourceSatellite: null, sourceMedialodistic: null, sourceOther: null, agr_offer: false };

const accSlice = createSlice({
    name: 'acc',
    initialState: accSliceInitialState,
    reducers: {
        setAcc: (
            state,
            { payload: { prefferedSourceTV, prefferedSourceUnit, sourceSatellite, sourceMedialodistic, sourceOther, agr_offer } }: PayloadAction<AccountPreference>
        ) => {
            state.prefferedSourceTV = prefferedSourceTV;
            state.prefferedSourceUnit = prefferedSourceUnit;
            state.sourceSatellite = sourceSatellite;
            state.sourceMedialodistic = sourceMedialodistic;
            state.sourceOther = sourceOther;
            state.agr_offer = agr_offer;
        }
    },
    extraReducers: (builder) => {
        builder.addMatcher(
            userApiV1.endpoints.getCurrentAccount.matchFulfilled,
            (state, { payload }) => {
                const tmp = payload.results.additional;
                if (tmp) {
                    state.prefferedSourceTV = tmp.prefferedSourceTV
                    state.prefferedSourceUnit = tmp.prefferedSourceUnit
                    state.sourceSatellite = tmp.sourceSatellite
                    state.sourceMedialodistic = tmp.sourceMedialodistic
                    state.sourceOther = tmp.sourceOther
                    state.agr_offer = payload.results.agr_offer
                }
            }
        ).addMatcher(
            commonApi.endpoints.login.matchRejected,
            (state, { payload }) => {
                state.prefferedSourceTV = null
                state.prefferedSourceUnit = null
                state.sourceSatellite = null
                state.sourceMedialodistic = null
                state.sourceOther = null
                state.agr_offer = false
            }
        )
    },
});

export const { setCredentials } = slice.actions
export const { setRole } = roleSlice.actions
export const { reducer: roleReducer } = roleSlice
export const { setAcc } = accSlice.actions
export const { reducer: accReducer } = accSlice

export default slice.reducer;

// Export hooks for usage in functional components
export const {
    useAccountLoginMutation,
    useGetDashboardQuery,
    useGetSpecsQuery,
    useGetSummaryQuery,
    useGetUnitsQuery,
    useGetXlsMutation,
    useLazyGetDashboardQuery,
    useLazyGetSpecsQuery,
    useLazyGetSummaryQuery,
    useLazyGetUnitsQuery,
    useLoginMutation,
    useSendFeedbackMutation,
    useSendToSupportMutation,
    useSuLoginMutation,
    useSuLogoutMutation,
} = commonApi;
