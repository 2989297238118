import { ButtonHTMLAttributes } from 'react';

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    size?: 'small' | 'normal';
}

function Button({size, ...props}: ButtonProps) {
    const cls = `btn ${props.disabled ? '_disabled' : ''} ${size === 'small' ? '_min' : ''} ${props.className || ''}`;
    return (
        <button type="button" {...props} className={cls}>
            {props.children}
        </button>
    );
}

export default Button;