import { useEffect, useRef, useState } from 'react';
import { ReactComponent as SearchSmall } from '../../assets/svg/SearcherIcon.svg';
import { useOutsideAlerter } from '../../hooks';

interface SearcherProps {
    placeholder?: string,
    value?: string,
    onChange?: (v: string) => void
}

export function Searcher({ placeholder, value, onChange }: SearcherProps) {
    const wrapperRef = useRef(null);
    const inputRef = useRef<HTMLInputElement | null>(null);
    const [active, setActive] = useState(!!value);
    const [localValue, setLocalValue] = useState(value || '');

    useEffect(() => {
        setActive((prev) => prev || !!value);
        setLocalValue(value || '');
    }, [value]);

    const setIsExpand = (v: boolean) => {
        if (!v) {
            if (!localValue) {
                setActive(v);
            }
        }
    };
    useOutsideAlerter(wrapperRef, setIsExpand);

    return (
        <div ref={wrapperRef} className={'main__controls-filter-search' + (active ? ' _active' : '')}>
            <input
                ref={inputRef}
                value={localValue}
                type="text"
                className="form__input main__controls-filter-search-input"
                size={1}
                placeholder={placeholder}
                onChange={(e) => {
                    setLocalValue(e.target.value || '');
                    onChange && onChange(e.target.value);
                }}
            />
            <div
                className="main__controls-filter-search-icon"
                onClick={() => {
                    setActive(!active);
                    setTimeout(() => {
                        inputRef.current?.focus();
                    }, 1);
                }}
            >
                <SearchSmall />
            </div>
        </div>
    );
}