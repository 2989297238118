import { t } from 'i18next';
import { HTMLAttributes } from 'react';
import { PersonDescription } from '../../../services/content_v1';
import { UserProfileMinimal } from '../../../services/users_v1';
import { useIsAdmin } from '../../common';

interface NoHideAdminProps extends HTMLAttributes<HTMLSpanElement> {
    user: UserProfileMinimal | PersonDescription | undefined | null;
}

function NoHideFIOAdmin({ user, ...props }: NoHideAdminProps) {
    const isAdmin = useIsAdmin();

    return (
        user?.hide_fio ?
            (isAdmin ?
                <span {...props} className="user-settings__label">{user.firstname || ''} {user.lastname || ''}</span>
                :
                <span {...props} className="user-settings__label">{t('account:HIDDEN_NAME')}</span>
            ) :
            `${user?.firstname || ''} ${user?.lastname || ''}`
    );
}

export default NoHideFIOAdmin;